@import '@angular/flex-layout/mq';

.fury-page {
  padding: 20px;
  display: block;

  @include layout-bp(lt-md) {
    padding: $padding-md;
  }
}
