.fury-tabs {

  .mat-tab-labels {
    background: $theme-app-bar;

    .mat-tab-label {

      &.mat-tab-label-active {
        color: $theme-text;
        opacity: 1;
      }
    }
  }

  .mat-tab-body-wrapper {
    flex: 1;
  }
}

.innerdashbody{
  .mat-tab-header{
      border-bottom: none;
      overflow: inherit;
  }
  .mat-tab-label-container{
    overflow: inherit;
  }
  .mat-tab-labels{
    position: absolute;
    top: -50px;
    left:-39px;
  }
  .mat-tab-label{
    padding: 0 45px !important;
    border-radius: 5px;
    background: #fff;
    margin-left: 15px;
    opacity: 1;
    .mat-tab-label-content{
      font-size: 1rem;
    }
    @media (max-width: 480px) {
      padding: 0 25px !important;
    }
  }
  .mat-ink-bar{
    background-color: inherit;
    display: none;
  }
  .mat-tab-label-active{
    background: #000;
    color: #fff;
    opacity: 1;
  }
  .mat-tab-body{
    padding: 95px 0 45px;
    @media (max-width: 650px) {
      padding: 185px 0 45px;
    }
  }
  .mat-form-field-type-mat-date-range-input .mat-form-field-infix{
    display: inline-flex;
    align-items: center;
    padding-bottom: 3px;
    padding-top: 3px;
  }
  .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper{
    top: auto!important;
  }
  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination{
    @media (max-width: 650px) {
      display: none;
    }
  }
}
