/* You can add global styles to this file, and also import other style files */
@import "./@fury/styles/core";
@import "./app/app.theme";
button{
    cursor: pointer;
}

.mat-pegig .mat-paginator-page-size-select .mat-select-min-line{
    position: relative;
     &:after{
         position: absolute;
         left: 0;
         top: 0;
         content: "All";
         background: #fff;
         width: 100%;
     }
    }

    // .mat-datepicker-toggle{
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    // }

    .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
        width: 200px;
        height: 100%;
    }
    .daterange .mat-form-field-appearance-outline .mat-form-field-flex{
        height: 55px;
    }
    .custom-modalbox .mat-dialog-container{
        padding: 0;
        overflow: hidden;
    }
    .custom-modalbox .mat-dialog-content{
        margin: 0;
        padding: 0 10px;
        padding-bottom: 40px;
        box-sizing: border-box;
    }
    .open_class{
        .listcont li{
            width: 14.4%!important;
        }
    }
    .mat-expansion-panel-header.mat-expanded{
        height: 48px;
    }

    .datapick .mat-form-field-appearance-fill .mat-form-field-infix{
        padding: 0;
        border: none;
    }
    .datapick .mat-form-field-appearance-fill .mat-form-field-flex{
        padding: 0;
        background-color: inherit;
    }
    .datapick .mat-form-field-appearance-fill .mat-form-field-underline::before{
        display: none;
    }
    .datapick .mat-form-field.mat-form-field-invalid .mat-form-field-ripple{
        display: none;
    }
    .datapick .mat-form-field-wrapper{
        padding-bottom: 0;
    }
    .datapick .mat-form-field-underline{
        display: none!important;
    }
    .contentprt {
        .matlabel{
          color: #000;
        }
      }

    .data_calender .datapick .mat-form-field-appearance-outline .mat-form-field-infix{
        padding: 0.8em 0 1em 0;
    }
    .data_calender .datapick .mat-datepicker-toggle{
        top: -5px;
    }

    .viewdatapick.datapick .mat-form-field-appearance-outline .mat-form-field-infix{
        padding: 0.8em 0 1em 0;
    }
    .viewdatapick.datapick .mat-datepicker-toggle{
        top: -4px;
        position: absolute;
        right: 0;
    }

    .mat-select-trigger{
        height: 100%;
      }
      .payment-info{
        .mat-dialog-container{
            padding: 0;
        }
      }
    .inputdatepicker {
        width: 100%;
        .mat-form-field-infix{
            padding: 0;
            border: none;
        }
        .mat-datepicker-toggle{
            position: absolute;
            right: 0;
        }
        .mat-form-field-flex{
            padding: 0!important;
        }
        .mat-form-field-outline{
            display: none!important;
        }
      }
      .mat-menu-panel.menu_list{
        max-height: 500px;
      }
      .technician_prt{
        @media (max-width: 767px) {
            max-width: inherit;
            min-width: 100%;
        }
        .mat-dialog-container{
            padding: 0;
            overflow: hidden;
        }
      }

      .billing_prt::-webkit-scrollbar, .listcont::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      .billing_prt::-webkit-scrollbar-track, .listcont::-webkit-scrollbar-track {
        background: #d2d2d2;
      }
      .billing_prt::-webkit-scrollbar-thumb, .listcont::-webkit-scrollbar-thumb {
        background: #FA634E;
      }
      .billing_prt::-webkit-scrollbar-thumb:hover, .listcont::-webkit-scrollbar-thumb:hover  {
        background: #FA634E;
      }
      .billing_prt, .listcont {
        scrollbar-color: #FA634E #d2d2d2;
        scrollbar-width: thin;
      }
    .close{
        cursor: pointer;
    }

    .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false]{
        background-color: transparent;
    }
    .refund_process .mat-ink-bar{
        display: none;
    }
    .refund_process .mat-tab-nav-bar, .mat-tab-header{
        border-bottom: none;
    }
    .refund_process .mat-tab-label{
        background: #ccc;
        border-radius: 10px;
    }
    .refund_process .mat-tab-labels{
        justify-content: space-between;
        gap:10px;
    }
    .mat-tab-label{
        background: #e3e3e3 !important;
        margin-right: 0px;
    }
    .mat-tab-label-active{
        background: rgb(36 36 36) !important;
        color: #fff;
        opacity: 1 !important;
    }
    .mat-focus-indicator{
        display: none;
    }


    .customer_tab .mat-tab-group.mat-primary .mat-ink-bar {
        display: none;
      }
      
      .customer_tab .mat-tab-header {
        border-bottom: none;
        margin-bottom: 0px;
      }
     .customer_tab .mat-tab-label {
        opacity: 1;
        color: #000;
        border: 1px solid #eee;
        border-radius: 0px;
        background: #d7d7d7;
      }
    .customer_tab .mat-tab-label-active {
        background: #495f53;
        opacity: 1;
        color: #fff;
        border-radius: 0px;
      }
      .customer_tab h3{
        margin-bottom: 0;
      }

      .customer_list{
        table.mat-table{
            thead{
                tr{
                    background: #cbcbcb;
                    th{
                        font-weight: bold;
                        color: #000;
                        padding: 0;
                    }
                }
            }
            tbody{
                tr{
                    &:nth-child(even){
                        background: #eee;
                    }
                    
                }
            }
        }
    }
    .cdk-overlay-pane.addCompany{
        position: relative!important;
        .close{
            display: block!important;
            width: 15px;
            img{
                filter: brightness(0);
            }
        }
       .top_panel{
            display: flex;
        }
    }
    .scroll_container .mat-form-field{
        min-width: 27%;
    }
    .scroll_container .content, .scroll_container .assign_body{
        padding-right: 15px;
    }
   
    .custom_list fury-list{
        overflow: hidden;
        display: inline;
    }
    .customer_tab h2{
        margin-bottom: 10px;
    }
    .tabPrt .fury-list-header{
        display: none!important;
    }
    .log .formarea{
        padding: 0!important;
    }
    .log .top_panel{
        display: flex !important;
            justify-content: space-between !important;
            gap: 20px !important;
            margin-bottom: 10px !important;
            border-bottom: 1px solid #ccc !important;
            padding: 0 0 15px 0 !important;
            box-shadow: none!important;
            background: none!important;
    }
    .log .overflow-auto{
        overflow: inherit!important;
    }
    .log .table{
        max-height: 600px;
    }
    .partnerbody{
        .mat-tab-body{
            padding: 20px!important;
        }
    }
    .parner-dash{
        .mat-tab-labels{
            left: 0px!important;
            position: inherit!important;
            top:inherit!important;
        }
     }
     .fury-default .parner-dash .mat-tab-label-container {
        overflow: hidden;
    }
    .table_container{
        max-height: calc(100vh - 300px);
        width: 100%;
        overflow: auto;
    }
    .table_container::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
        height: 4px;
      }
      .table_container::-webkit-scrollbar-thumb {
        background-color: #A8A8A8;
        -webkit-box-shadow: 0 0 0.5px rgba(255,255,255,.5);
      }
      .table_container::-webkit-scrollbar-track {
        background: #c5c5c5;
      }
    .header-check{
        padding: 0 24px!important;
    }
    .fury-list-table .mat-form-field-appearance-fill .mat-form-field-flex{
        background-color: inherit;
    }
    .mat-paginator{
        background: #e8e8e8;
    }