.fury-page-layout {

}

.fury-page-layout-header {
  background: url("../../../assets/img/backgrounds/white-banner.webp") 0 0 no-repeat;
  color: #000;
  background-size: cover;

  fury-breadcrumbs {
    .crumb, .link {
      color: #969696;

      &:hover {
        color: #000;
      }
    }

    .crumb.current, .crumb.current .link {
      color: #000;
    }
  }
}
