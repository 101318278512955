@use 'sass:math';

html, body {
  min-height: 100%;
  height: 100%;
  font: var(--font-body-1);

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
}

.fury-scrollblock {
  position: fixed;
  width: 100%;
}

.fury-collapsable-sidenav {
  overflow: visible !important;
  border-right: none !important;

  .mat-drawer-inner-container {
    overflow: visible !important;
  }
}

.fury-flex-form-field {
  .mat-form-field-infix {
    width: 0;
  }
}

.mat-menu-panel.fury-inbox-mail-label-list-menu {
  max-height: 350px;
  padding-top: math.div($spacing, 3);

  .fury-inbox-mail-label-list-menu-subheading {
    font: var(--font-caption);
    color: yellow; // TODO: $theme-secondary-text
    font-weight: 500;
    padding-left: math.div($spacing, 2);
    padding-right: math.div($spacing, 2);
    margin-bottom: math.div($spacing, 3);
  }

  .mat-checkbox {
    margin-right: math.div($spacing, 2);
  }

  .fury-inbox-mail-label-list-create {
    padding-left: math.div($spacing, 2);
    padding-right: math.div($spacing, 2);
  }

  .fury-inbox-mail-label-list-color, .fury-inbox-mail-label-list-name {

    .mat-form-field-infix {
      width: auto;
    }
  }
}

.fury-inbox-mail-label-list-color-select {
  .fury-inbox-mail-label-list-color-select-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    margin-right: math.div($spacing, 2);
    vertical-align: middle;
  }
}

h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
}

pre {
  margin: 0;
}

code {
  white-space: pre;
}

p {
  line-height: 1.5;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
}

.quickpanel .mat-tab-body-content,
.quickpanel .mat-tab-body-wrapper,
.quickpanel mat-tab-body,
.quickpanel mat-tab-group {
  height: 100%;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging > *:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
img{
  max-width: 100%;
  vertical-align: middle;
}
.sub-button{
  box-shadow: 0 7px 14px -4px #00000091, inset 0 -6px 8px -4px #00000091!important;
  background-color: #FA634E!important;
  color: #fff!important;
}
.can-button{
  box-shadow: 0 7px 14px -4px #00000091, inset 0 -6px 8px -4px #00000091!important;
  background-color: #000!important;
  color: #fff!important;
}
.logo{
  width: 200px;
}
.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: inherit!important;
}
.uploadfilebtn{
  background: #1dbf73!important;
    color: #fff!important;
    border-radius: 10px;
    padding: 10px 20px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.65px;
    border:none;
    outline: none;
    box-shadow: 0 7px 14px -4px rgb(0 0 0 / 57%), inset 0 -6px 8px -4px rgb(0 0 0 / 57%);
}
  .uploadsec{
      margin: 0px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    
      .imgsec{
        width: 201px;
        min-height: 202px;
        background: #ebe7e2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        margin-bottom: 10px;
        position: relative;
        img{
          width: 100%;
          object-fit: cover;
          height: 160px;
        }
      }
}

.mat-table-sticky-border-elem-right {
  border-left: 1px solid #e0e0e0;
}

.mat-table-sticky-border-elem-left {
  border-right: 1px solid #e0e0e0;
}

.custom-class .mat-option:last-child .mat-option-text {
  display: none;
  position: relative;
}

.custom-class .mat-option:last-child:after {
  content: "All";
  float: left;
  text-transform: none;
  top: 4px;
  position: relative;
}

.generatebtn .mat-form-field-suffix button {
  border: none;
  background: none;
  outline: none;
  color: orange;
  cursor: pointer;
}
.generatebtn .mat-form-field-suffix {
  top: 0!important;
}
.removebadge{
    position: absolute;
    right: -5px;
    top: -5px;
    background: #fff;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #eee;
    cursor: pointer;
}
.contentprt > div h1 {
  font-size: 22px;
}
.contentprt > div p {
  margin-top: 0;
}
.listcont li{
  @media screen and (max-width:991px) {
    width: auto!important;
  }
  @media screen and (max-width:480px) {
    width: 100%!important;
  }
}
.shortfilter{
  width: 13px;
  cursor: pointer;
}
table th{
  position: relative;
}
.filteringprt{
  background: #fff;
  padding: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  z-index: 222;
  .btnprt{
    display: flex;
    justify-content: space-between;
  }
}
.role_checkbox{
  label{
    padding-left: 20px;
    display: block;
    margin: 10px 0;
  }
}
